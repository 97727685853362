import React from 'react'
import styled, { withTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { setCurrentProject } from '../redux/actions/currentProject'

import Action from './Action'
import Icon from './Icon'

const ReadStatusIcon = ({ isRead, handleMarkAsRead }) =>
  isRead ? (
    <IconContainer>
      <Icon name="envelope-open" />
    </IconContainer>
  ) : (
    <IconContainer>
      <Icon name="envelope" onClick={handleMarkAsRead} />
    </IconContainer>
  )

const Severity = withTheme(({ severity, theme }) =>
  severity == 'error' ? (
    <InfoContainer>
      <Icon name="exclamation-circle" color={theme.colors.alert} />
    </InfoContainer>
  ) : severity == 'warning' ? (
    <InfoContainer>
      <Icon name="info-circle" color={theme.colors.warn} />
    </InfoContainer>
  ) : (
    <InfoContainer>
      <Icon name="info-circle" color={theme.colors.success} />
    </InfoContainer>
  )
)

const IconContainer = styled.div`
  grid-column: 3;
  grid-row: 1;
  display: flex;
  justify-content: flex-end;
`

const InfoContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
`

const ReadStatusIconStyled = styled(ReadStatusIcon)`
  grid-column: 3;
`

const Title = styled.div`
  color: black;
  grid-column: 2;
  grid-row: 2;
  overflow: hidden;
`

const Description = styled.p`
  color: #979797;
`

const Tag = styled.div`
  grid-column: 2;
  grid-row: 1;
  display: flex;
`

const Timestamp = styled.div`
  grid-row: 2;
`

const Time = styled.div`
  font-weight: bold;
`

const Date = styled.div``

const TagLink = styled.div`
  background: ${(props) => props.theme.colors.lightGray};
  border-radius: 16px;
  margin-right: 10px;
  padding-right: 6px;
  display: inline-block;
  align-items: center;
`

const TagLinkText = styled.p`
  width: auto;
  height: 32px;
  margin-left: 14px;
  margin-right: 4.5px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.text};
  line-height: 32px;
  text-align: center;
`

const Container = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100px 1fr 50px;
  grid-template-rows: 40px 40px;
  border-top: 1px solid grey;
  max-width: 960px;
  padding: 14px;
`

const NotificationItem = ({
  id,
  title,
  isRead,
  markAsReadHandler,
  time,
  date,
  actionUrl,
  actionName,
  severity,
  projectId,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const setProjectAndNavigate = (to) => (e) => {
    dispatch(setCurrentProject(projectId))
    history.push(to)
    e.stopPropagation()
  }

  return (
    <>
      <Container>
        <Severity severity={severity} />
        <Timestamp>
          <Time>{time}</Time>
          <Date>{date}</Date>
        </Timestamp>
        <Tag>
          {actionUrl && (
            <Action onClick={setProjectAndNavigate(actionUrl)}>
              <TagLink>
                <TagLinkText>{actionName || 'Zum Fehler'}</TagLinkText>
              </TagLink>
            </Action>
          )}
        </Tag>
        <Action to={`/notifications/${id}`}>
          <Title>{title}</Title>
        </Action>
        <ReadStatusIconStyled
          isRead={isRead}
          handleMarkAsRead={markAsReadHandler}
        />
      </Container>
    </>
  )
}

const StyledNotificationItem = styled(NotificationItem)``

export default StyledNotificationItem
