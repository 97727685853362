import { transparentize } from 'polished'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { withTheme } from 'styled-components'
import is from 'styled-is'

import { fontSmoothing } from '../styles'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 314px);
  grid-gap: 2rem;
  justify-content: center;
  margin-top: 2rem;

  ${is('smallGap')`
    grid-gap: 1rem;
  `};
`

export const GridItem = styled.li`
  display: flex;
  ${is('order')`
    order: ${(props) => props.order}
  `};
`

const Container = styled.div`
  width: 100%;
`

const Background = styled.div`
  ${fontSmoothing};
  color: ${({ theme }) => theme.colors.background};
  background-color: ${({ theme }) => theme.colors.gray};
  background-image: ${({ image }) => `url(${image})` || 'none'};
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;

  min-height: 240px;

  display: flex;
  justify-content: stretch;
`

const Blur = styled.div`
  flex: 1;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: ${({ theme }) => transparentize(0.9, theme.colors.text)};
  backdrop-filter: blur(2px);
  position: relative;
`

const Alert = styled.span`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

const Title = styled.h3`
  font-size: ${({ theme }) => theme.font.h1};
  line-height: 1em;
  font-weight: normal;
  margin: 0;
  text-align: center;
`

const Subtitle = styled.h4`
  font-size: ${({ theme }) => theme.font.body};
  line-height: 1em;
  font-weight: normal;
  margin: 0.5rem 0 0 0;
`

const Children = styled.div`
  flex: 1;
  display: flex;
  gap: 0.5rem;
  margin: 1rem 0;
  flex-wrap: wrap;

  ${is('reversed')`
    flex-wrap: wrap-reverse;
  `};

  ${is('centered')`
    margin: 1rem 20px;
    justify-content: center;
  `};
`

const LabeledValueWrapper = styled.div`
  width: 64px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
`

const Value = styled.span`
  font-size: 30px;
  color: ${(props) => props.color};
`
const Label = styled.span`
  font-size: 12px;
  text-align: center;
  hyphens: auto;
`

export const Actions = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: currentColor;

  margin: 1rem -32px;

  &::before,
  &::after {
    flex-shrink: 1;
    flex-grow: 1;
    content: '';
    display: block;
    border-top: 1px solid currentColor;
  }
`

export const ActionDivider = styled.div`
  flex: 0 0 1rem;
  border-top: 1px solid currentColor;
`

export const LabelledValue = ({ value, label, valueColor }) => (
  <LabeledValueWrapper>
    <Value color={valueColor}>{value}</Value>
    <Label>{label}</Label>
  </LabeledValueWrapper>
)

const Tile = ({
  alert,
  actions,
  children,
  description,
  footer,
  subtitle,
  title,
  centered,
  reversed,
  ...backgroundProps
}) => {
  return (
    <Container>
      <Background {...backgroundProps}>
        <Blur>
          {alert && <Alert>{alert}</Alert>}
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <Children centered={centered} reversed={reversed}>
            {children}
          </Children>
          <Subtitle>{description}</Subtitle>
          <Actions>{actions || <span></span>}</Actions>
          <Subtitle>{footer}</Subtitle>
        </Blur>
      </Background>
    </Container>
  )
}

Tile.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
}

export default withTheme(Tile)
