import React, { useState, useEffect } from 'react'

export const useFetch = ({ url, options, refetch, skip }) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    // the request is skipped when the parameter "skip" is not false, undefined or null
    if (skip) {
      return null
    }

    const fetchData = async () => {
      try {
        const res = await fetch(url, options)
        const json = await res.json()
        if (res.status >= 400) {
          setError(json)
        } else {
          setData(json)
        }
      } catch (error) {
        setError(error)
      }
    }
    fetchData()
  }, [refetch].flat())

  return { data, error }
}

export default useFetch
