import styled, { keyframes } from 'styled-components'
import React, { useEffect, useRef, useState } from 'react'
import Icon from '../../Icon'
import { useTranslation } from 'react-i18next'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9); /* Optional: scale down slightly */
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`

const MenuItem = styled.div`
  padding: 15px;
  cursor: pointer;
  &:hover {
    background: #4b8d34;
    color: white;
  }
`

const MenuContainer = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  top: ${(props) => `${props.top}px`};
  left: ${(props) => `${props.left}px`};
  z-index: 999;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden; // needed to show the hovered background of the items also with the same border-radius
  animation: ${(props) => (props.show ? fadeIn : 'none')} 0.3s ease;
`

export const ContextMenu = ({ onEdit, onShow, onShowOnMap }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [position, setPosition] = useState({ top: 0, left: 0 })
  const buttonRef = useRef(null)
  const menuRef = useRef(null)

  // close the context menu when the user clicks outside of it
  useEffect(() => {
    if (open) {
      const handleClickOutside = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
          setOpen(false)
        }
      }

      // Attach the click event listener to the document
      document.addEventListener('click', handleClickOutside)

      return () => {
        // Remove the event listener when the component unmounts
        document.removeEventListener('click', handleClickOutside)
      }
    }
  }, [open])

  // close the context menu when scroll or resize event occurs
  useEffect(() => {
    if (open) {
      const handleScroll = () => {
        console.log('scroll or resize event triggered')
        // Close the context menu when scrolling occurs
        setOpen(false)
      }

      window.addEventListener('scroll', handleScroll, true)
      window.addEventListener('resize', handleScroll, true)

      return () => {
        // Remove the event listener when the component unmounts
        window.removeEventListener('scroll', handleScroll, true)
        window.removeEventListener('resize', handleScroll, true)
      }
    }
  }, [open])

  const toggleMenu = (e) => {
    e.stopPropagation() // stop propagation to suppress the click event of the parent div
    e.preventDefault()
    const buttonRect = e.target.getBoundingClientRect()
    setPosition({ top: buttonRect.bottom, left: buttonRect.left })
    setOpen(!open)
  }

  return (
    <>
      <div ref={buttonRef} onClick={toggleMenu}>
        <Icon
          name="ellipsis-vertical"
          color="#000"
          bordered={false}
          small={true}
        />
      </div>
      {open && (
        <MenuContainer
          ref={menuRef}
          show={open}
          top={position.top}
          left={position.left}
        >
          <MenuItem onClick={onShow}>{t('common:show')}</MenuItem>
          <MenuItem onClick={onEdit}>{t('common:edit')}</MenuItem>
          <MenuItem onClick={onShowOnMap}>
            {t('attributes:sensor:showOnMap')}
          </MenuItem>
        </MenuContainer>
      )}
    </>
  )
}
