import React, { useState } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import styled from 'styled-components'
import { Label } from './index'
import { FormGroup, Input, InputField, InputWrapper } from '../forms/index'

const MapInputWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`
const MapContainer = styled.div`
  position: absolute;
  left: 0;
  top: 20%;
  width: 100%;
  height: 50%;
  z-index: 10;
  display: ${(props) => (props.visible ? 'block' : 'none')};
`

const CloseButton = styled.div`
  cursor: pointer;
  height: 40px;
  font-size: 32px;
  text-align: right;
  margin-right: 0.5em;
  margin-left: 0.5em;
`

export const CoordinatePicker = ({
  label,
  placeholder,
  name,
  rowWidth,
  type,
  icon,
  noLine,
  lat,
  lng,
  initialZoomWithoutPosition,
  initialCoordinatesWithoutPosition,
}) => {
  const [mapVisible, setMapVisible] = useState(false)
  const [markerPosition, setMarkerPosition] = useState({ lng, lat })

  const positionPresent = !!markerPosition.lng && !!markerPosition.lat

  const handleInputClick = () => {
    setMapVisible(true)
  }

  const handleMapClick = (event, input) => {
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    setMarkerPosition({ lng, lat })
    input.onChange(`${lng}, ${lat}`)
    setMapVisible(false)
    // Exit fullscreen if enabled
    if (document.fullscreenElement) {
      document.exitFullscreen()
    }
  }

  return (
    <FormGroup hidden={type === 'hidden'} rowWidth={rowWidth}>
      <Label htmlFor={name}>{label}</Label>
      <InputField icon={icon != null} noLine={noLine}>
        <InputWrapper>
          <Input name={name}>
            {({ input }) => (
              <MapInputWrapper>
                <Input
                  {...input}
                  type="text"
                  placeholder={placeholder}
                  onClick={handleInputClick}
                  readOnly
                />
                {mapVisible && (
                  <MapContainer visible={mapVisible}>
                    <CloseButton onClick={() => setMapVisible(false)}>
                      x
                    </CloseButton>
                    <LoadScript googleMapsApiKey={process.env.GOOGLE_API_KEY}>
                      <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        center={
                          positionPresent
                            ? markerPosition
                            : initialCoordinatesWithoutPosition
                        }
                        mapTypeId="hybrid"
                        zoom={positionPresent ? 14 : initialZoomWithoutPosition}
                        onClick={(e) => handleMapClick(e, input)}
                      >
                        {positionPresent && (
                          <Marker position={markerPosition} />
                        )}
                      </GoogleMap>
                    </LoadScript>
                  </MapContainer>
                )}
              </MapInputWrapper>
            )}
          </Input>
        </InputWrapper>
        {icon}
      </InputField>
    </FormGroup>
  )
}

export default CoordinatePicker
