import React, { createRef, Component } from 'react'

import styled from 'styled-components'
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library'
import Action from './Action'
import Icon from './Icon'
import Modal from './Modal'

const Video = styled.video`
  flex: 1;
`

class BarcodeScanner extends Component {
  state = {
    devices: [],
    currentDeviceId: undefined,
  }

  video = createRef()

  onDecode = (result, error) => {
    if (error != null && !(error instanceof NotFoundException)) {
      console.error(error)
    }

    if (result != null) {
      console.log(result)
      this.props.onResult(result.text)
    }
  }

  async componentDidMount() {
    this.codeReader = new BrowserMultiFormatReader()
    const devices = await this.codeReader.getVideoInputDevices()

    this.setState({
      devices: devices.map(({ deviceId, label }) => ({ deviceId, label })),
    })

    let currentDeviceId = window.localStorage.getItem('currentDeviceId')

    if (devices.find(({ deviceId }) => deviceId === currentDeviceId) == null) {
      currentDeviceId = undefined
    }

    if (devices.length > 0) {
      this.startScanning(currentDeviceId || devices[0].deviceId)
    }
  }

  startScanning = (currentDeviceId) => {
    this.reset()
    this.setState({ currentDeviceId })
    if (this.codeReader) {
      this.codeReader.decodeFromInputVideoDeviceContinuously(
        currentDeviceId,
        this.video.current,
        this.onDecode
      )
    }
    window.localStorage.setItem('currentDeviceId', currentDeviceId)
  }

  reset = () => {
    if (this.codeReader) {
      this.codeReader.reset()
    }
  }

  componentWillUnmount() {
    this.reset()
  }

  render() {
    const { onClose } = this.props
    const { devices, currentDeviceId } = this.state
    return (
      <Modal
        actions={
          <>
            <Action vertical onClick={onClose}>
              <Icon name="cancel" background="white" />
            </Action>
          </>
        }
      >
        {devices.length > 0 ? (
          <select
            onChange={(e) => this.startScanning(e.target.value)}
            value={currentDeviceId}
          >
            {devices.map(({ deviceId, label }) => (
              <option key={deviceId} value={deviceId}>
                {label}
              </option>
            ))}
          </select>
        ) : undefined}
        <Video width="938" height="584" ref={this.video} />
      </Modal>
    )
  }
}

export default BarcodeScanner
