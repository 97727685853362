import styled from 'styled-components'
import is from 'styled-is'

export const FlexContainer = styled.div`
  display: flex;
  min-height: 0;

  ${is('alignCenter')`
    align-items: center;
  `};

  ${is('center')`
    justify-content: center;
  `};

  ${is('right')`
    justify-content: flex-end;
  `};

  ${is('distributed')`
    justify-content: space-between;
  `};

  ${is('flex')`
    flex: ${(props) => props.flex || 1};
  `};

  ${is('marginBottom')`
    margin-bottom: 1rem;
  `};
`
