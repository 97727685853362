import React from 'react'
import styled from 'styled-components'

const SearchResultWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1em;
  cursor: pointer;
  &:hover {
    background-color: lightgrey;
  }
`

const UserSearchResult = ({ user, onClick }) => {
  const { firstName, lastName } = user
  return (
    <SearchResultWrapper onClick={onClick}>
      <div>{`${firstName} ${lastName}`}</div>
    </SearchResultWrapper>
  )
}

export default UserSearchResult
