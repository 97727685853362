import styled, { css } from 'styled-components'
import is from 'styled-is'

const actions = css`
  ${is('actions')`
    text-align: right;
    width: calc(0.5rem + (20px + 1rem) * ${({ actions }) =>
      Number.isInteger(actions) ? actions : 3});
  `};
`

export const Title = styled.caption`
  text-align: left;
`

export const Head = styled.thead``

export const Foot = styled.tfoot``

export const Row = styled.tr`
  margin: -0.5rem 0;
`

const cellPadding = css`
  padding: 0.5rem;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`

export const Header = styled.th`
  text-align: left;
  ${actions};
  ${cellPadding};

  ${is('quiet')`
    font-weight: normal;
  `};
`

export const Column = styled.td`
  ${actions};
  ${cellPadding};

  ${is('fixed')`
    width: 120px;
  `}

  ${is('icon')`
    width: calc(16px + 0.5rem);
    vertical-align: top;
  `}
`

export const Body = styled.tbody`
  ${is('indented')`
    ${Column}:first-child {
      padding-left: 0.5rem;
    }
  `};
`

export default styled.table`
  width: 100%;

  ${is('spacious')`
    td {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  `};

  ${is('divided')`
    border-collapse: collapse;
    td, th {
      border-bottom: 1px solid ${({ theme }) => theme.colors.inactive};
    }
  `};

  ${is('attributes')`
    margin-bottom: 4rem;

    th:first-child:not(:only-child), td:first-child:not(:only-child) {
      width: 100px;
    }
  `};
`
