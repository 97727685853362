import React from 'react'
import { withTheme } from 'styled-components'

import PropTypes from 'prop-types'
import { ContextMenu } from './ContextMenu'
import { Background, Header, Title } from './SensorValueTileStyles'
import { Body } from './Body'

const SensorValueTile = ({
  id,
  title,
  timestamp,
  unit,
  value,
  valueMin,
  valueMax,
  valueLower,
  valueHigher,
  onClick,
  onShowOnMap,
  onShow,
  onEdit,
  showMenu,
}) => {
  // checks thresholds and maps the current value to 'warn', 'crit' or ''
  const alarmLevel = () => {
    const v = parseFloat(value)
    const vLower = parseFloat(valueLower)
    const vHigher = parseFloat(valueHigher)
    const vMin = parseFloat(valueMin)
    const vMax = parseFloat(valueMax)
    if ((v <= vLower && v > vMin) || (v >= vHigher && v < vMax)) {
      return 'warn'
    } else if (v <= vMin || v >= vMax) {
      return 'crit'
    } else {
      return ''
    }
  }
  return (
    <Background onClick={onClick}>
      <Header>
        <Title>{title}</Title>
        {showMenu && (
          <ContextMenu
            onEdit={onEdit}
            onShow={onShow}
            onShowOnMap={onShowOnMap}
          />
        )}
      </Header>
      <Body
        onClick={onClick}
        value={value}
        unit={unit}
        valueMin={valueMin}
        valueMax={valueMax}
        timestamp={timestamp}
        alarmLevel={alarmLevel()}
      />
    </Background>
  )
}

SensorValueTile.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  timestamp: PropTypes.string,
  unit: PropTypes.string,
  valueMin: PropTypes.number,
  valueMax: PropTypes.number,
  valueLower: PropTypes.number,
  valueHigher: PropTypes.number,
  onShow: PropTypes.func, // click on context menu entry "show"
  onEdit: PropTypes.func, // click on context menu entry "edit"
  onShowOnMap: PropTypes.func, // click on context menu entry "show on map"
  onClick: PropTypes.func, // click on the tile itself
  showMenu: PropTypes.bool, // shows the three dot menu button if true
}

export default withTheme(SensorValueTile)
