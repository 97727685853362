import { formatValue } from './helpers'
import { useTranslation } from 'react-i18next'
import parseISO from 'date-fns/parseISO'
import { formatDistanceStrict, isAfter, subHours } from 'date-fns'
import { locales as dateLocales } from '../../../i18n'
import {
  Boundaries,
  Boundary,
  BoundIcon,
  Timestamp,
  Unit,
  Value,
  ValueUnitWrapper,
} from './SensorValueTileStyles'
import React from 'react'

export const Body = ({
  unit,
  value,
  valueMin,
  valueMax,
  alarmLevel,
  timestamp,
  onClick,
}) => {
  const valueLength = String(formatValue(value)).length
  const { t, i18n } = useTranslation()
  const now = new Date()
  const parsedTimestamp = parseISO(timestamp)
  let receivedAt
  let showReceivedAt = false
  if (timestamp != undefined) {
    receivedAt = formatDistanceStrict(parsedTimestamp, now, {
      locale: dateLocales[i18n.language],
    })
    showReceivedAt = isAfter(subHours(now, 1), parsedTimestamp)
  }

  return (
    <div onClick={onClick}>
      <ValueUnitWrapper>
        <Value alarmLevel={alarmLevel} valueLength={valueLength}>
          {formatValue(value)}
        </Value>
        <Unit alarmLevel={alarmLevel}>{unit}</Unit>
      </ValueUnitWrapper>
      <Boundaries>
        <Boundary>{formatValue(valueMin)}</Boundary>
        <BoundIcon text name="bell" color="black" />
        <Boundary>{formatValue(valueMax)}</Boundary>
        <Timestamp>{showReceivedAt && receivedAt}</Timestamp>
      </Boundaries>
    </div>
  )
}
