import React from 'react'
import { useSelector } from 'react-redux'
import { getCurrentRole } from '../../redux/reducers'

function HideForRoles({ roles, children }) {
  const role = useSelector(getCurrentRole)

  if (roles.includes(role)) {
    return null
  } else {
    return <>{children}</>
  }
}

export default HideForRoles
