import React from 'react'
import { withTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import is from 'styled-is'
import PropTypes from 'prop-types'

const Children = styled.div`
  border-radius: 19px;
  border: 1px solid ${(props) => props.theme.colors.brandPrimary};
  width: 225px;
  ${is('fullWidth')`
    width: 100%;
  `};
  height: 38px;
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  justify-content: space-around;
  overflow: hidden;
`

const Label = styled.a`
  font-weight: 600;
  font-size: ${({ theme }) => theme.font.small};
  font-family: 'Asap', sans-serif;
  line-height: 38px;
  border-right: 1px solid ${(props) => props.theme.colors.brandPrimary};
  cursor: pointer;
  flex: 1;
  text-align: center;

  &:last-child {
    border-right: none;
  }

  ${is('active')`
    background: ${(props) => props.theme.colors.brandGradient};
  `};
`

export const Tab = ({ label }) => {
  return <Label>{label}</Label>
}

const TabBar = ({
  labels,
  active,
  onChange,
  translate = false,
  t,
  ...props
}) => {
  return (
    <Children {...props}>
      {labels.map((label, index) => {
        return (
          <Label
            key={index}
            active={label === active}
            onClick={() => onChange(label)}
          >
            {translate ? t(label) : label}
          </Label>
        )
      })}
    </Children>
  )
}

TabBar.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  active: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  translate: PropTypes.bool,
}

export default withTranslation()(withTheme(TabBar))
