import React from 'react'
import styled, { css, withTheme } from 'styled-components'

import Icon from './Icon'
import Action from './Action'

const Container = styled.span`
  background: ${(props) => props.theme.colors.lightGray};
  border-radius: 16px;
  padding-right: 6px;
  display: inline-flex;
  align-items: center;
`
const Outer = styled.div`
  justify-content: center;
  display: flex;
`

const Text = styled.p`
  width: 206px;
  height: 32px;
  margin-left: 14px;
  margin-right: 4.5px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.text};
  line-height: 32px;
  text-align: center;
`

const TagListItem = ({ text, onDelete, deleteable }) => {
  return (
    <Outer>
      <Container>
        <Text>{text}</Text>
        {deleteable && (
          <Action onClick={onDelete}>
            <Icon small text name="cancel" />
          </Action>
        )}
      </Container>
    </Outer>
  )
}

export default withTheme(TagListItem)
