import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import styled, { withTheme } from 'styled-components'

import Action, { StyledAction } from './Action'
import Icon from './Icon'

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &,
  & > ${StyledAction} {
    font-size: ${({ theme }) => theme.font.small};
  }
`

const Pages = styled.span`
  font-weight: 600;
  margin-right: 1rem;
`

const Pagination = ({ currentPage, totalPages, onPaginate, theme }) => {
  const { t } = useTranslation()
  const isFirst = currentPage === 1
  const isLast = currentPage === totalPages
  return (
    <Container>
      <Pages>{t('paginate', { currentPage, totalPages })}</Pages>
      <Action onClick={() => onPaginate(1)} disabled={isFirst}>
        <Icon
          text
          color={isFirst ? theme.colors.gray : theme.colors.text}
          bordered={false}
          name="chevron-double-left"
        />
      </Action>
      <Action onClick={() => onPaginate(currentPage - 1)} disabled={isFirst}>
        <Icon
          text
          color={isFirst ? theme.colors.gray : theme.colors.text}
          bordered={false}
          name="chevron-left"
        />
      </Action>
      <Action onClick={() => onPaginate(currentPage + 1)} disabled={isLast}>
        <Icon
          text
          color={isLast ? theme.colors.gray : theme.colors.text}
          bordered={false}
          name="chevron-right"
        />
      </Action>
      <Action onClick={() => onPaginate(totalPages)} disabled={isLast}>
        <Icon
          text
          color={isLast ? theme.colors.gray : theme.colors.text}
          bordered={false}
          name="chevron-double-right"
        />
      </Action>
    </Container>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPaginate: PropTypes.func.isRequired,
}

export default withTheme(Pagination)
