import { transparentize } from 'polished'
import React from 'react'
import styled, { withTheme } from 'styled-components'
import Icon from './Icon'
import Action from './Action'

const Positioner = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding-top: 32px;
  z-index: 2;
  background-color: ${({ theme }) =>
    transparentize(0.2, theme.colors.background)};
`
const Container = styled.span`
  background: ${(props) => props.theme.colors.inactive};
  border-radius: 16px;
  padding-right: 6px;
  display: inline-flex;
  align-items: center;
`

const Text = styled.p`
  width: 255px;
  height: 32px;
  margin-left: 14px;
  margin-right: 4px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  color: ${(props) => props.theme.colors.text};
  line-height: 32px;
  text-align: center;
`

const FlashMessage = ({ show, text, onCancle }) => {
  return (
    <div>
      {show && (
        <Positioner onClick={onCancle}>
          <Container>
            <Text>{text}</Text>
            <Action onClick={onCancle}>
              <Icon small text name="cancel" />
            </Action>
          </Container>
        </Positioner>
      )}
    </div>
  )
}

export default withTheme(FlashMessage)
