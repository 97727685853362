import debounce from 'debounce'
import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Icon from './Icon'
import { SearchField as RawSearch } from './forms'

import { getUseTilesSetting } from '../redux/reducers'
import BarcodeScanner from './BarcodeScanner'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
`

const SearchField = styled(RawSearch)`
  border: 1px solid ${(props) => props.theme.colors.text};
  height: 34px;
  border-radius: 17px;

  flex: 1;
  margin: 0 0 0 1rem;
`

const Space = styled.span`
  width: 1rem;
`

const ListToolbar = ({
  children,
  useBarcode,
  onSearch = () => {},
  searchColumns = ['name'],
}) => {
  const [showTiles, setShowTiles] = useState(useSelector(getUseTilesSetting))
  const toggleListType = () => setShowTiles(!showTiles)
  const [searchParams, updateSearchParams] = useState('')
  const [isScanning, setIsScanning] = useState(false)

  const debouncedSearch = useRef(debounce(onSearch, 1000)).current

  const throttleSearch = (inputValue) => {
    updateSearchParams(inputValue)
    const params = new window.URLSearchParams()
    if (inputValue) {
      params.append(`q[${searchColumns.join('_or_')}_cont]`, inputValue)
    }
    // params.append('per_page', '25')
    // params.append('page', '1')

    debouncedSearch(params)
  }

  const handleScanResult = (result) => {
    throttleSearch(result)
    setIsScanning(false)
  }

  return (
    <>
      <Container>
        <Icon name={showTiles ? 'list' : 'tiles'} onClick={toggleListType} />
        {useBarcode && (
          <>
            <Space />
            <Icon name="qrcode" onClick={() => setIsScanning(true)} />
            {isScanning && (
              <BarcodeScanner
                onClose={() => setIsScanning(false)}
                onResult={handleScanResult}
              />
            )}
          </>
        )}

        <SearchField
          value={searchParams}
          onChange={(e) => {
            throttleSearch(e.target.value)
          }}
        />
      </Container>
      {children({ showTiles, searchParams })}
    </>
  )
}

ListToolbar.propTypes = {
  children: PropTypes.func.isRequired,
  useBarcode: PropTypes.bool,
  onSearch: PropTypes.func,
  searchColumns: PropTypes.arrayOf(PropTypes.string),
}

export default ListToolbar
