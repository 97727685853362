import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCurrentUser } from '../../redux/reducers'
import { tokenAgeInSeconds } from '../../lib/auth'
import { H1 } from '../type'

const FadeInAndOutAnimation = styled.div`
  /* Hide the message by default */
  opacity: 0;
  animation: fade-in 1s forwards, fade-out 1s forwards 3s;

  /* Define the fade-in animation */
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /* Define the fade-out animation */
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

export const WelcomeMessage = () => {
  const { t } = useTranslation()
  const { name, token } = useSelector(getCurrentUser)
  const signInDuration = tokenAgeInSeconds(token)
  // show welcome message if user is signed in for less than 60 seconds
  const showWelcomeMessage = signInDuration < 60

  return (
    showWelcomeMessage && (
      <div style={{ textAlign: 'center' }}>
        <FadeInAndOutAnimation>
          <H1>{t('greeting', { name })}</H1>
        </FadeInAndOutAnimation>
      </div>
    )
  )
}

export default WelcomeMessage
