import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { H2 } from './type'
import Action, { Actions } from './Action'
import Icon from './Icon'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  width: 100%;
  margin: -1rem -2rem 0;
  padding: 2rem 2rem 1rem;

  background: ${({ theme }) => theme.colors.background};

  z-index: 1;

  @media (min-width: 1440px) {
    width: calc(100% - 375px);
  }

  & + * {
    margin-top: 95px;
  }
`

const Title = styled(H2)`
  margin-bottom: 0;
`

const Toolbar = ({
  title,
  search,
  history,
  onBack = () => history.goBack(),
  children,
}) => {
  return (
    <Header>
      <Action onClick={onBack}>
        <Icon name="back" />
      </Action>
      {title && <Title>{title}</Title>}
      {search && search}
      <Actions>{children}</Actions>
    </Header>
  )
}

export default withRouter(Toolbar)
