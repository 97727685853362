// overrides the color of the sensor value when it is out of bounds (above oder below alarm system config thresholds)
import styled, { css } from 'styled-components'
import { match } from 'styled-is'
import Icon from '../../Icon'

export const sensorValueColor = css`
  color: ${(props) => props.theme.colors.success};
  ${match('alarmLevel', 'warn')`
    color: ${(props) => props.theme.colors.warn};
  `};
  ${match('alarmLevel', 'crit')`
    color: ${(props) => props.theme.colors.critical};
  `};
`
export const Background = styled.li`
  background-color: ${(props) => props.theme.colors.inactive};
  width: 140px;
  height: 99px;
  border-radius: 16px;
  padding: 15px 20px;
  margin: 0 0 1rem;
  cursor: pointer;
`
export const Title = styled.div`
  color: black;
  line-height: 1.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`
export const ValueUnitWrapper = styled.div`
  height: 32px;
  display: flex;
  align-items: baseline;
`
export const Value = styled.span`
  ${sensorValueColor}
  padding-right: 5px;
  max-width: 70px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  line-height: 32px;
  font-size: ${(props) => (props.valueLength > 4 ? 16 : 32)}px;
`
export const Unit = styled.span`
  ${sensorValueColor}
  max-width: 40px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  line-height: 32px;
  font-size: 16px;
`
export const Boundaries = styled.div`
  padding-top: 0.5em;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr 6fr;
  justify-content: space-evenly;
`
export const Boundary = styled.span`
  font-size: 10px;
  line-height: 11px;
`
export const Timestamp = styled.span`
  font-size: 10px;
  line-height: 11px;
  text-align: right;
`
export const BoundIcon = styled(Icon)`
  height: 11px;
  width: 11px;
  color: ${(props) => props.theme.colors.text};
`
