import styled from 'styled-components'

export const SensorList = styled.ul`
  list-style-type: none;
  margin: 2rem 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`
