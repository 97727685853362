import styled from 'styled-components'

import { H1 } from '../../components/type'

const ListTitle = styled(H1)`
  display: flex;
  align-items: center;
  a {
    margin-left: 1rem;
  }
`

export default ListTitle
