import React, { useRef, useState } from 'react'
import debounce from 'debounce'
import { useFetch } from '../../hooks'
import { SearchField } from '../forms'
import UserSearchResult from './UserSearchResult'
import styled from 'styled-components'
import { url } from '../../lib/api'

const SearchResultsContainer = styled.div`
  width: fit-content;
  max-height: 10em;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid lightgrey;
`

const UserSearch = ({ token, projectId, onResultClick }) => {
  const [searchExpression, setSearchExpression] = useState('')

  const debouncedSearch = useRef(debounce(setSearchExpression, 500)).current
  const apiHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  const response = useFetch({
    url: `${url}/projects/${projectId}/users?q[first_name_or_last_name_or_email_cont]=${searchExpression}`,
    options: {
      method: 'GET',
      headers: apiHeaders,
    },
    refetch: searchExpression,
  })

  const handleResultClick = (user) => {
    onResultClick(user.id)
    // reset search expression so the results will disappear after selection
    setSearchExpression('')
  }

  const searchResults = (response || { data: [] }).data

  return (
    <div>
      <SearchField onChange={(e) => debouncedSearch(e.target.value)} />
      {/*<input type="text" onChange={(e) => debouncedSearch(e.target.value)} />*/}

      {searchExpression !== '' && searchResults && (
        <SearchResultsContainer>
          {searchResults.map((user) => (
            <UserSearchResult
              user={user}
              onClick={() => handleResultClick(user)}
            />
          ))}
        </SearchResultsContainer>
      )}
    </div>
  )
}

export default UserSearch
