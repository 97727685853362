import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import Action from '../Action'
import Icon from '../Icon'
import Table, { Body, Column, Head, Header, Row, Title } from '../Table'
import HideForRoles from '../auth/HideForRoles'

const ShiftRows = ({ shifts, type, onNavigate }) => {
  const { t } = useTranslation()
  return shifts.map(
    ({ id, name, currentOccurrenceEndsAt, nextOccurrenceStartsAt }, index) => {
      return (
        <Row key={index}>
          <Column fixed>{name}</Column>
          <Column>
            {t(`attributes:shift:${type}`, {
              currentOccurrenceEndsAt,
              nextOccurrenceStartsAt,
            })}
          </Column>
          <Column actions={1}>
            <HideForRoles roles={['farmer']}>
              <Action to={`/shifts/${id}`} onClick={onNavigate}>
                <Icon small name="forward" />
              </Action>
            </HideForRoles>
          </Column>
        </Row>
      )
    }
  )
}

const ProgramShiftTable = ({ programs, onNavigate, title, linkToPrograms }) => {
  return (
    <Table>
      <Title>
        {/* {title} */}
        {linkToPrograms && (
          <HideForRoles roles={['farmer']}>
            <Action horizontal to="/programs" onClick={onNavigate}>
              <Icon small name="forward" />
            </Action>
          </HideForRoles>
        )}
      </Title>
      {(programs || []).map(
        ({ id, name, shifts: { running = [], upcoming = [] } }, index) => {
          return (
            <Fragment key={index}>
              <Head>
                <Row>
                  <Header colSpan={2}>{name}</Header>
                  <Header actions={1}>
                    <HideForRoles roles={['farmer']}>
                      <Action to={`/programs/${id}`} onClick={onNavigate}>
                        <Icon small name="forward" />
                      </Action>
                    </HideForRoles>
                  </Header>
                </Row>
              </Head>
              <Body>
                <ShiftRows
                  shifts={running}
                  type="running"
                  onNavigate={onNavigate}
                />
                <ShiftRows
                  shifts={upcoming}
                  type="upcoming"
                  onNavigate={onNavigate}
                />
              </Body>
            </Fragment>
          )
        }
      )}
    </Table>
  )
}

export default ProgramShiftTable
